html {
  scroll-behavior: smooth;
}

::selection {
  background-color: theme-color('primary');
  color: color-yiq(theme-color('primary'));

  .bg-primary & {
    background-color: theme-color('dark');
  }
}

.btn-success {
  color: color('white');
}
