@mixin full-width() {
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    background: inherit;
    content: '';
    z-index: -1;
  }
}
