@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .h1-font-size#{$infix} {
      font-size: $h1-font-size;
    }

    .h2-font-size#{$infix} {
      font-size: $h2-font-size;
    }

    .h3-font-size#{$infix} {
      font-size: $h3-font-size;
    }

    .h4-font-size#{$infix} {
      font-size: $h4-font-size;
    }

    .h5-font-size#{$infix} {
      font-size: $h5-font-size;
    }

    .h6-font-size#{$infix} {
      font-size: $h6-font-size;
    }
  }
}
