.registration-form {
  > legend {
    display: none;
  }

  .btn-facebook {
    $btn-facebook-bg: #44609f;
    @include button-variant($btn-facebook-bg, $btn-facebook-bg);
    overflow: hidden;
  }

  .form-control-feedback {
    display: none;
  }

  .has-danger .form-control-feedback {
    display: block;
  }

  .tooltip-inner {
    min-width: 250px;
  }
}

#cgu-datas {  // sass-lint:disable-line no-ids
  font-size: 67%;
}
