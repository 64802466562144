.section {
  padding: map-get($spacers, 4) 0;

  &--hero {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0;
    background-image: url('#{$image-path}/background.jpg');
    background-repeat: no-repeat;
    background-position: 20% 140px;
    background-size: cover;

    @include media-breakpoint-up(md) {
      background-position: center top;
    }

    &__container {
      @include make-container();
      @include make-container-max-widths();
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__description {
      margin-right: map-get($spacers, 3);
      margin-bottom: 0;
      padding: map-get($spacers, 1) map-get($spacers, 2);
      border-radius: 0 0 $border-radius $border-radius;
      background-color: theme-color('primary');
      color: color('white');
      text-align: center;
    }

    &__body {
      @include full-width();
      padding: map-get($spacers, 3) 0;
      background-color: $body-bg;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding: map-get($spacers, 5) 0;
        background-color: transparent;
        text-align: right;
      }
    }

    &__footer {
      @include full-width();
      padding: map-get($spacers, 3);
      background-color: $body-bg;
      text-align: center;

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}

.registration-links {
  @include make-container();
  position: sticky;
  bottom: 0;
  padding-top: map-get($spacers, 3);
  padding-bottom: map-get($spacers, 3);
  background-color: rgba($body-bg, .8);
}
