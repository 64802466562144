// sass-lint:disable no-ids, no-important

[id^='ez-login-form-container'] #ez-login-form-email,
[id^='ez-login-form-container'] #ez-login-form-lfpassword {
  display: block;
  width: 100% !important;
  max-width: none !important;
  margin-bottom: $form-group-margin-bottom;
  padding: $input-btn-padding-y $input-btn-padding-x !important;
  border: $input-btn-border-width solid $input-border-color;
  border-radius: $input-border-radius !important;
  background-color: $input-bg;
  font-size: $font-size-base !important;
  line-height: $input-btn-line-height !important;
  box-shadow: none;
}

[id^='ez-login-form-container'] #ez-login-form-submit {
  display: inline-block;
  width: auto !important;
  margin: 0 !important;
  padding: $input-btn-padding-y !important;
  border: $input-btn-border-width solid transparent !important;
  border-radius: $btn-border-radius;
  border-color: theme-color('success') !important;
  background-color: theme-color('success');
  color: $white;
  font-size: $font-size-base;
  font-weight: $btn-font-weight;
  line-height: $input-btn-line-height;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
}
